import React, { useEffect, useState } from 'react'
import { Image, Table } from "antd"
import { useLocation } from "react-router-dom"
import { getHunterDetails, getHunterTeam, getHunterEarnings } from "../../api/hunting"
import { filterJoin, objTurnArr, positionType as positionList } from '../../utils/specification'
import { earnings as columns } from "./table"
export default function Details() {
  const { state: id } = useLocation()
  const [userInfo, setUserInfo] = useState({})
  const [list, setList] = useState([])
  const [status, setStatus] = useState('')
  positionList[0] = '全部'
  const positionType = objTurnArr(positionList)
  const [params, setParams] = useState({ pageNo: 1, pageSize: 10, hunterId: id, positionType: 0 })
  const [earnings, setEarnings] = useState([])
  const [total, setTotal] = useState(0)
  useEffect(() => {
    getHunterDetails(id).then(({ result }) => {
      setUserInfo(result)
    })
  }, [id])
  useEffect(() => {
    let data = {}
    if (status !== '') {
      data.status = status
    }
    getHunterTeam(id, data).then(({ result }) => {
      setList(result)
    })
  }, [status, id])
  useEffect(() => {
    let data = JSON.parse(JSON.stringify(params))
    for (let index in data) {
      if (!data[index]) {
        delete data[index]
      }
    }
    getHunterEarnings(data).then(({ result }) => {
      let { total, records } = result
      setTotal(total)
      setEarnings(records)
    })
  }, [params, id])
  return (
    <div className='huntingDetails'>
      <div className='flexAlignCenter'>
        <div className='basicsInfo'>
          <img src={userInfo.avatar} className='avatar' alt="" />
          <div className='flexAlignCenter' style={{ marginTop: "10px" }}>
            <div className='name'>{userInfo.name}</div>
            <img className='sex' src={`/assets/${userInfo.sex === 1 ? 'male' : userInfo.sex === 2 ? 'female' : ''}.png`} alt="" />
          </div>
          <div style={{ marginTop: "10px" }}>
            <span className='the'>{userInfo.graduate}届</span>
            <span className='school'>{filterJoin([userInfo.school, userInfo.specialty], ' · ')}</span>
          </div>
          <div className='achievement'>
            <div className='detailsTitle'>我的成就</div>
            <div className='achievementWrap'>
              {
                userInfo.isFlash === 1 ? <div className='achievementItem item1'>明日菁英</div> : null
              }
              {
                userInfo.hunter === 1 ? <div className='achievementItem item2'>校园预备委员</div> : null
              }
              {
                userInfo.isGeneral === 1 ? <div className='achievementItem item2'>实践优等生</div> : null
              }
              {
                userInfo.isTeam === 1 ? <div className='achievementItem item3'>省协会委员</div> : null
              }
            </div>
          </div>
          <div className='contact'>
            <div className='detailsTitle'>联系方式</div>
            <div className='phone'>{userInfo.phone}</div>
            {
              userInfo.createTime ? <div className='tiem'>{userInfo.createTime.split(' ')[0]}</div> : null
            }
          </div>
          <div className='prove'>
            <div>
              {
                userInfo.superiorName ? <>
                  <div className='detailsTitle'>上级预备委员</div>
                  <div className='name'>姓名：{userInfo.superiorName}</div>
                </> : null
              }
            </div>
            <Image width={246} height={146} src={userInfo.studentCard}
            />
          </div>
        </div>
        <div className='rightData'>
          <div className='dataCard'>
            <div className='flexAlignFlexEnd'>
              <div className='dataItem'>
                <div>{userInfo.commission}</div>
                <div>猎才佣金</div>
              </div>
              <div className='vertical'></div>
              <div className='dataItem'>
                <div>{userInfo.pluralism || 0}</div>
                <div>推荐兼职</div>
              </div>
              <div className='vertical' style={{ height: '80px', background: '#1764FE' }}></div>
              <div className='dataItem'>
                <div>{userInfo.practice || 0}</div>
                <div>推荐实习</div>
              </div>
              <div className='vertical' style={{ height: '60px', background: '#F47E53' }}></div>
              <div className='dataItem'>
                <div>{userInfo.activity || 0}</div>
                <div>推荐活动</div>
              </div>
              <div className='vertical' style={{ height: '40px', background: '#FFECE3 ' }}></div>
              <div className='dataItem'>
                <div>{userInfo.fullTime || 0}</div>
                <div>推荐全职</div>
              </div>
            </div>
          </div>
          <div className='teamCard'>
            <div className='teamHeader flexAlignCenter'>
              <div className='headerTitle'>团队成员</div>
              <div className='statistical'>共 <span className='number'>{userInfo.team || 0}</span> 人，<span className='huntingNumber'>{userInfo.hunterTeam}</span> 个预备委员</div>
            </div>
            <div className='classify flexAlignCenter'>
              <div className={filterJoin(['classifyItem', status === '' ? 'classifyItemAct' : null])} onClick={() => { setStatus('') }}>全部</div>
              <div className={filterJoin(['classifyItem', status === 0 ? 'classifyItemAct' : null])} onClick={() => { setStatus(0) }}>普通成员</div>
              <div className={filterJoin(['classifyItem', status === 1 ? 'classifyItemAct' : null])} onClick={() => { setStatus(1) }}>预备委员</div>
            </div>
            <div className='scroll'>
              {
                list.map((key, index) => {
                  return (
                    <div className='teamItem flexAlignCenter' key={key.id}>
                      <div className='serial flexs'>{index + 1}</div>
                      <div className='name'>{key.name}</div>
                      <div className='school flex1'>{filterJoin([key.school, key.specialty])}</div>
                      <div className='phone'>{key.phone}</div>
                      <div className={filterJoin(['identity', key.hunter === 1 ? 'hunting' : null])}> {key.hunter === 1 ? '预备委员' : '普通'} </div>
                      <div className='time flexs'>{key.bornDate}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className='recommended'>
        <div className='recommendedHeader flexAlignCenter'>
          <div className='recommendedTitle'> <img src="/assets/recommended.png" alt="" /> 收益明细</div>
          <div className='classify flexAlignCenter'>
            {
              positionType.map((key, index) => {
                return <div className={filterJoin(['classifyItem', params.positionType === index ? 'classifyItemAct' : null])} onClick={() => { setParams({ ...params, ...{ positionType: index } }) }}>{key}</div>
              })
            }
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={earnings}
          showHeader={false}
          scroll={{ y: 500 }}
          pagination={{
            total: total,
            current: params.pageNo,
            pageSize: params.pageSize,
            onChange: (pageNo, pageSize) => { setParams({ ...params, ...{ pageNo, pageSize } }) }
          }}
        />
      </div>
    </div>
  )
}
