import { filterJoin, type, positionType, interviewState } from "../../utils/specification"
export const columns = [
    {
        title: '序号',
        width: 98,
        align: 'center',
        render: (t, r, i) => <>{i + 1}</>
    },
    {
        title: '名称',
        align: 'center',
        dataIndex: 'name'
    },
    {
        title: '已推',
        align: 'center',
        render: (key) => <>{key.readss + key.unRead}</>
    },
    {
        title: '已读',
        align: 'center',
        dataIndex: 'readss',
        render: (t) => <>{t}人已读</>
    },
    {
        title: '未读',
        align: 'center',
        dataIndex: 'unRead',
        render: (t) => <>{t}人未读</>

    },
    {
        title: '发布时间',
        align: 'center',
        dataIndex: 'createTime'
    }
]


export const details = [
    {
        title: '序号',
        width: 98,
        render: (t, r, i) => <>{i + 1}</>,
        align: 'center'
    },
    {
        title: '基本信息',
        render: (key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div>
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.name}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key.school, key.specialty], ' · ')}
                    </div>
                </div>
            </div>
    },
    {
        title: '标签',
        align: 'center',
        render: (t, key) =>
            <div className='label'>
                {key.isFlash === 1 ? <div className='labelItem item1'>明日菁英</div> : ''}
                <div className='labelItem item2'>预备委员</div>
                {key.isGeneral === 1 ? <div className='labelItem item2'>实践优等生</div> : ''}
                {key.isTeam === 1 ? <div className='labelItem item3'>省协会委员</div> : ''}
            </div>
    },
    {
        title: '联系方式',
        dataIndex: 'phone',
        align: 'center'
    },
    {
        title: '信用金',
        dataIndex: 'credit',
        align: 'center',
        render: (t) => <>{t || 0}</>
    },
    {
        title: '入驻时间',
        dataIndex: 'createTime',
        align: 'center'
    }
]

export const resumeAudit = [
    {
        title: '基本信息',
        width: 300,
        render: (key) =>
            <div className='basisInfo'>
                <img className='avatar' src={key.avatar} alt="" />
                <div className="info">
                    <div className='flexAlignBaseline'>
                        <div className='nickname'>{key.student}</div>
                        <div className='gender'><img src={`/assets/${key.sex === 1 ? 'male' : key.sex === 2 ? 'female' : ''}.png`} alt="" /></div>
                        <div className='the'>{key.age ? key.age + '岁' : ''}</div>
                        <div className='the'>{key.graduate ? key.graduate + '届' : ''}</div>
                    </div>
                    <div className='schoolInfo'>
                        {filterJoin([key.school, key.specialty], ' · ')}
                    </div>
                </div>
            </div>
    },
    {
        title: '标签',
        align: 'center',
        render: (key) =>
            <div className='label'>
                {key.isFlash === 1 ? <div className='labelItem item1'>明日菁英</div> : null}
                {key.hunter === 1 ? <div className='labelItem item2'>预备委员</div> : null}
                {key.isGeneral === 1 ? <div className='labelItem item2'>实践优等生</div> : null}
                {key.isTeam === 1 ? <div className='labelItem item3'>省协会委员</div> : null}
            </div>
    },
    {
        title: '岗位信息',
        align: 'center',
        render: (key) =>
            <div>
                <div className="join">{filterJoin([key.positionName,])}</div>
                <div className="type">{type[key.type]}</div>
                <div className="type">{key?.companyName}</div>
            </div>
    },
    {
        title: '推荐时间',
        dataIndex: 'recommendedTime',
        align: 'center',
    },
    {
        title: '类型',
        dataIndex: 'positionType',
        align: 'center',
        render: (index) => <>{positionType[index]}</>
    },
    {
        title: '状态',
        align: 'center',
        render: (key) => <>{interviewState[key.status]}{key.status === 'un_hired' && key?.remark ? ':' + key.remark : ''}</>
    }
]