import React, { useEffect, useState } from 'react'
import { Input, Table, Dropdown, Menu, message } from "antd"
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { getStudentsList, setStudentsInfo } from "../../api/students"
import { getPushList, studentsAddPush } from "../../api/position"
import { columns } from "./table"
import { getUserInfo } from '../../utils/auth'
import { Select } from "antd"
import { getSchoolList } from "../../api/school"
import "./index.scss"
export default function School() {
    const { Option } = Select
    const userInfo = JSON.parse(getUserInfo()) || ''
    console.log(userInfo, '用户信息')
    const { pathname } = useLocation()
    const [params, setParams] = useState({ pageNo: 1, pageSize: 10, schoolId: '' })
    const [total, setTotal] = useState(0)
    const [list, setList] = useState([])
    const [options, setOptions] = useState([])
    const [school, setSchool] = useState([])
    const navigate = useNavigate()
    // 定义定时器
    let timeout;
    // input 输入选择
    const inputChange = (e) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        const fake = () => {
            setParams({ ...params, ...{ name: e.target.value ? `,${e.target.value},` : e.target.value, pageNo: 1 } })
        };
        timeout = setTimeout(fake, 1000);
    }
    // 获取学生列表
    const getStudentsApi = () => {
        let data = JSON.parse(JSON.stringify(params))
        for (let index in data) {
            if (!data[index]) {
                delete data[index]
            }
        }
        if (userInfo?.nature === 'school') {
            data.schoolId = userInfo?.id
        }
        getStudentsList(data).then(({ result }) => {
            let { total, records } = result
            setTotal(total)
            setList(records)
        })
    }
    // 学生添加黑名单
    const setStudentsApi = (data) => {
        setStudentsInfo(data).then(res => {
            if (res) {
                getStudentsApi()
            }
        })
    }
    // 学生推荐到人才库
    const studentsAddApi = (studentId, pushId) => {
        studentsAddPush({ studentId, pushId }).then(({ result }) => {
            message.info(result)
        })
    }
    // 获取推送库
    useEffect(() => {
        if (pathname === '/students/list') {
            getPushList({ pageSize: 100 }).then(({ result }) => {
                let { records } = result
                setOptions(records)
            })
        }
    }, [])
    useEffect(() => {
        if (pathname === '/students/list') {
            getStudentsApi()

        }
    }, [params])
    useEffect(() => {
        getSchoolList({ pageSize: 10000 }).then(({ result }) => {
            setSchool(result.records)
        })
    }, [])
    return (
        <>
            {
                pathname === '/students/list' ? <div className='students'>
                    <div className='screening'>
                        <Input placeholder='请输入学生名字' onChange={inputChange} prefix={<span className='iconfont'>&#xe67b;</span>} style={{ width: "391px" }} />
                        {
                            userInfo?.nature === 'sys' ? <Select value={params.schoolId} showSearch style={{ width: 240 }} onChange={(e) => { setParams({ ...params, ...{ schoolId: e } }) }}>
                                {
                                    school.map(key => {
                                        return <Option value={key.id} key={key.id}>{key.name}</Option>
                                    })
                                }
                                <Option value=''>全部学校</Option>
                            </Select> : null
                        }
                    </div >
                    <Table
                        columns={[...columns, ...[{
                            title: '操作',
                            align: 'center',
                            width: 128,
                            render: (key) =>
                                <div className='operation' onClick={e => { e.stopPropagation(); }}>
                                    <Dropdown overlay={<Menu items={[
                                        {
                                            key: 1,
                                            label: (<div className='operationBox'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (key.isFlash === 1 && userInfo?.nature === "school") return false;
                                                    setStudentsApi({ id: key.id, isFlash: key.isFlash ? 0 : 1 })
                                                }}
                                            >{key.isFlash ? '取消明日菁英' : '给予明日菁英'}</div>),
                                            disabled: key.isFlash === 1 && userInfo?.nature === "school"
                                        },
                                        {
                                            key: 2,
                                            label: (<div className='operationBox'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setStudentsApi({ id: key.id, isTeam: key.isTeam ? 0 : 1 })
                                                }}
                                            >{key.isTeam ? '取消省协会委员' : '给予省协会委员'}</div>)
                                        },
                                        {
                                            key: 3,
                                            label: (<div className='operationBox'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setStudentsApi({ id: key.id, isGeneral: key.isGeneral ? 0 : 1 })
                                                }}
                                            >{key.isGeneral ? '取消实践优等生' : '给予实践优等生'}</div>)
                                        },
                                        {
                                            key: 4,
                                            label: (<div className='operationBox'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setStudentsApi({ id: key.id, isBlack: key.isBlack ? 0 : 1 })
                                                }}>{key.isBlack ? '移除黑名单' : '加入黑名单'}</div>)
                                        }
                                    ]} />} placement="bottom" arrow>
                                        <div className="edit iconfont">&#xe8ac;</div>
                                    </Dropdown>
                                    <div className='vertical'></div>
                                    <Dropdown overlay={<Menu items={
                                        options.map((item, index) => {
                                            return {
                                                key: index + 1,
                                                label: (<div className='operationBox' onClick={e => { e.stopPropagation(); studentsAddApi(key.id, item.id) }}>{item.name}</div>)
                                            }
                                        })
                                    } />} placement="bottom" arrow>
                                        <div className="shopping iconfont">&#xe69c;</div>
                                    </Dropdown>
                                </div>
                        }]]}
                        dataSource={list}
                        scroll={{ y: 'calc(100vh - 100px)' }}
                        rowKey="id"
                        onRow={r => { return { onClick: e => { navigate('details', { state: r.id }) } } }}
                        pagination={{
                            total: total,
                            current: params.pageNo,
                            pageSize: params.pageSize,
                            showTotal: (total) => <div style={{ marginRight: 20 }}>共计{total}人</div>,
                            onChange: (pageNo, pageSize) => { setParams({ pageNo, pageSize }) }
                        }}
                    ></Table>
                </div > : <Outlet />
            }
        </>

    )
}
